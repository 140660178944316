@import 'froala_editor';
@import 'froala_style';

@import 'codemirror/codemirror'; 

//plugins

//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/char_counter';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/char_counter.min';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/code_view';
@import '../../bower_components/froala-wysiwyg-editor/css/plugins/code_view.min';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/colors';
@import '../../bower_components/froala-wysiwyg-editor/css/plugins/colors.min';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/draggable';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/draggable.min';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/emoticons';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/emoticons.min';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/file';
// @import '../../bower_components/froala-wysiwyg-editor/css/plugins/file.min';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/fullscreen';
@import '../../bower_components/froala-wysiwyg-editor/css/plugins/fullscreen.min';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/image';
@import '../../bower_components/froala-wysiwyg-editor/css/plugins/image.min';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/image_manager';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/image_manager.min';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/line_breaker';
@import '../../bower_components/froala-wysiwyg-editor/css/plugins/line_breaker.min';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/quick_insert';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/quick_insert.min';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/table';
@import '../../bower_components/froala-wysiwyg-editor/css/plugins/table.min';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/video';
//@import '../../bower_components/froala-wysiwyg-editor/css/plugins/video.min';
 

[style="position: absolute; bottom: 0px; left: 0px; z-index: 9999;"] {
	display: none !important;
}