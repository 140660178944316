.fr-box.fr-basic .fr-element {
  line-height: 140%;
  background: #666;
  padding: 20px;
  color: #eeeeee;
}

.fr-element ul,
.fr-element ol,
ul.fr-tag,
ol.fr-tag {
  padding-left: 25px;
}

.fr-element p,
p.fr-tag {
  margin: 0 0 5px;
}

.fr-element h1,
h1[class="fr-tag"] {
  font-size: 36px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px;
}

.fr-element h2,
h2[class="fr-tag"] {
  font-size: 30px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px;
}

.fr-element h3,
h3[class="fr-tag"] {
  font-size: 24px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px;
}

.fr-element h4,
h4[class="fr-tag"] {
  font-size: 18px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
}

.fr-element h5,
h5[class="fr-tag"] {
  font-size: 14px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
}

.fr-element h6,
h6[class="fr-tag"] {
  font-size: 12px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
}

.fr-element {
  a,
  .link {
      color: #C39A2A;
      text-decoration: none;
      font-weight: 500;
      transition: color .3s;
      cursor: pointer;

      &:hover { 
          color: lighten(#C39A2A, 20%);
          transition: color .3s;
      }

      &.link-blue {
          color: #0091ea;
          &:hover { color: lighten(#0091ea, 20%); }
      }

      &.link-underline {
          text-decoration: underline;
      }
  }

  table {
    border-collapse: collapse;
    td {
      border: 1px dashed #474a51;
    }
  }

  .style {
    &--lower-font-size {
      font-size: 13px;
    }

    &--higher-font-size {
      font-size: 18px;
    }

    &--default-color {
      color: #C39A2A;
    }

    &--uppercase {
      text-transform: uppercase;
    }

    &--lowercase {
      text-transform: none;
    }

    &--bold {
      font-weight: 700;
    }

    &--normal {
      font-weight: 400;
    }

    &--italic {
      font-style: italic;
    }

    &--align-center {
      text-align: center;
    }

    &--gray {
      color: #eee;
    }

    &--underline {
      text-decoration: underline;
    }

    &--low-letterspacing {
      letter-spacing: 1px;
    }

    &--medium-letterspacing {
      letter-spacing: 2px;
    }

    &--high-letterspacing {
      letter-spacing: 3px;
    }
  } 

  .generic-title {
      font-weight: 900;
      text-transform: uppercase;
      position: relative;
      color: #eee;

      &.is-left {
          text-align: left;
          &::before {
              left: 0;
              margin-left: 0;
          }
      }

      &::before {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          width: 130px;
          height: 1px;
          background: #C39A2A;
          margin-left: -65px;
      }

      &--large {
          font-size: 36px;
          padding-top: 0;
          margin-top: 0;
          padding-bottom: 30px;
          margin-bottom: 30px;
          letter-spacing: 6px;
      }
  }
}


.fr-video { 
  position: relative;
  text-align: center;
  display: inline-block;
  /*
  &.fr-dvb {
    display: inline-block;
  }
  &.fr-fvr {
    text-align: right;
  }
  &.fr-fvl {
    text-align: left;
  }
  */
}

.fr-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  display: block;
}

.fr-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-header-wrapper {
  padding-top: 200px;
  padding-bottom: 200px;
}

.fr-wrapper {
  blockquote {
      color: #f4f4f4;
      font-size: 25px;
      line-height: 31px;
      font-style: italic;
      font-weight: 300;
      position: relative;
      padding: 50px 54px;
      margin: 0;
      overflow: hidden;

      &:before,
      &:after {
          color: #f4f4f4;
          font-size: 75px;
          line-height: 75px;
          display: block;
          position: absolute;
          font-family: 'Times New Roman', sans-serif;
          letter-spacing: 0;
      }

      &:before {
          content: '“';
          top: 16px;
          left: -12px; 
      }

      &:after {
          content: '”';
          bottom: -14px;
          right: 0px;
      }
  }
}