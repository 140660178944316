@charset "UTF-8";
/*!
 * froala_editor v2.3.4 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2016 Froala Labs
 */
.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.fr-element,
.fr-element:focus {
  outline: 0px solid transparent; }

.fr-box.fr-basic .fr-element {
  text-align: initial;
  color: #000000;
  padding: 10px 0 0 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: auto;
  min-height: 40px; }

.fr-element {
  background: transparent;
  position: relative;
  z-index: 2;
  -webkit-user-select: auto; }

.fr-element a {
  user-select: auto;
  -o-user-select: auto;
  -moz-user-select: auto;
  -khtml-user-select: auto;
  -webkit-user-select: auto;
  -ms-user-select: auto; }

.fr-element.fr-disabled {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

.fr-element [contenteditable="false"] {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

.fr-element [contenteditable="true"] {
  outline: 0px solid transparent; }

.fr-box a.fr-floating-btn {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  height: 32px;
  width: 32px;
  background: #ffffff;
  color: #42a5f5;
  -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  outline: none;
  left: 0;
  top: 0;
  line-height: 32px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  text-align: center;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: none; }

.fr-box a.fr-floating-btn svg {
  -webkit-transition: transform 0.2s ease 0s;
  -moz-transition: transform 0.2s ease 0s;
  -ms-transition: transform 0.2s ease 0s;
  -o-transition: transform 0.2s ease 0s;
  fill: #42a5f5; }

.fr-box a.fr-floating-btn i {
  font-size: 14px;
  line-height: 32px; }

.fr-box a.fr-floating-btn.fr-btn + .fr-btn {
  margin-left: 10px; }

.fr-box a.fr-floating-btn:hover {
  background: #ebebeb;
  cursor: pointer; }

.fr-box a.fr-floating-btn:hover svg {
  fill: #42a5f5; }

.fr-box .fr-visible a.fr-floating-btn {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1); }

iframe.fr-iframe {
  width: 100%;
  border: none;
  position: relative;
  display: block;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.fr-wrapper {
  position: relative;
  z-index: 1; }

.fr-wrapper::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.fr-wrapper .fr-placeholder {
  position: absolute;
  font-size: 12px;
  color: #FAFAFA;
  z-index: 2;
  display: none;
  top: 0;
  left: 0;
  overflow: hidden; }

.fr-wrapper.show-placeholder .fr-placeholder {
  display: block; }

.fr-wrapper ::selection {
  background: #b5d6fd;
  color: #000000; }

.fr-wrapper ::-moz-selection {
  background: #b5d6fd;
  color: #000000; }

.fr-box.fr-rtl .fr-wrapper .fr-placeholder {
  right: 0;
  left: auto; }

.fr-box.fr-basic .fr-wrapper {
  background: #ffffff;
  border: 0px;
  border-top: 0;
  top: 0;
  left: 0; }

.fr-box.fr-basic.fr-rtl .fr-wrapper .fr-placeholder {
  right: 0;
  left: auto; }

.fr-box.fr-basic.fr-top .fr-wrapper {
  border-top: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

.fr-box.fr-basic.fr-bottom .fr-wrapper {
  border-bottom: 0;
  border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

.fr-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 8px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  background: #222222;
  color: #ffffff;
  font-size: 11px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  left: -3000px;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  z-index: 9997; }

.fr-tooltip.fr-visible {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }

.fr-toolbar {
  color: #222222;
  background: #f1f1f1;
  position: relative;
  z-index: 4;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  text-align: left;
  border: 1px solid #ccc; }

.fr-toolbar::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.fr-toolbar.fr-rtl {
  text-align: right; }

.fr-toolbar.fr-inline {
  display: none;
  white-space: nowrap;
  position: absolute;
  margin-top: 10px; }

.fr-toolbar.fr-inline .fr-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #222222;
  position: absolute;
  top: -9px;
  left: 50%;
  margin-left: -5px;
  display: inline-block; }

.fr-toolbar.fr-inline.fr-above {
  margin-top: -10px;
  border-bottom: 5px solid #222222;
  border-top: 0; }

.fr-toolbar.fr-inline.fr-above .fr-arrow {
  top: auto;
  bottom: -9px;
  border-bottom: 0;
  border-top-color: inherit;
  border-top-style: solid;
  border-top-width: 5px; }

.fr-toolbar.fr-top {
  top: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

.fr-toolbar.fr-bottom {
  bottom: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  padding-left: 5px;
  padding-right: 5px; }

.fr-separator {
  background: #ebebeb;
  display: block;
  vertical-align: top;
  float: left; }

.fr-separator + .fr-separator {
  display: none; }

.fr-separator.fr-vs {
  height: 33px;
  width: 1px;
  margin: 2px; }

.fr-separator.fr-hs {
  clear: both;
  height: 1px;
  width: calc(100% - (2 * 2px));
  margin: 0 2px; }

.fr-separator.fr-hidden {
  display: none !important; }

.fr-rtl .fr-separator {
  float: right; }

.fr-toolbar.fr-inline .fr-separator.fr-hs {
  float: none; }

.fr-toolbar.fr-inline .fr-separator.fr-vs {
  float: none;
  display: inline-block; }

.fr-toolbar .fr-command.fr-btn,
.fr-popup .fr-command.fr-btn {
  background: transparent;
  color: #707070;
  -moz-outline: 0;
  outline: 0;
  border: 0;
  line-height: 1;
  cursor: pointer;
  text-align: left;
  margin: 0px 2px;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  z-index: 2;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  float: left;
  padding: 0;
  width: 30px;
  height: 37px;
  text-align: center; }

.fr-toolbar .fr-command.fr-btn i,
.fr-popup .fr-command.fr-btn i {
  display: inline-block;
  font-size: 20px;
  width: 19px;
  margin: 9px 5px;
  text-align: center;
  float: none; }

.fr-toolbar .fr-command.fr-btn span,
.fr-popup .fr-command.fr-btn span {
  font-size: 14px;
  display: block;
  line-height: 14px;
  min-width: 38px;
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 15px;
  font-weight: bold; }

.fr-toolbar .fr-command.fr-btn img,
.fr-popup .fr-command.fr-btn img {
  margin: 11px 11.5px;
  width: 15px; }

.fr-toolbar .fr-command.fr-btn.fr-active,
.fr-popup .fr-command.fr-btn.fr-active {
  color: #42a5f5;
  background: transparent; }

.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-selection,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-selection {
  width: auto; }

.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-selection span,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-selection span {
  font-weight: normal; }

.fr-toolbar .fr-command.fr-btn.fr-dropdown i,
.fr-popup .fr-command.fr-btn.fr-dropdown i,
.fr-toolbar .fr-command.fr-btn.fr-dropdown span,
.fr-popup .fr-command.fr-btn.fr-dropdown span,
.fr-toolbar .fr-command.fr-btn.fr-dropdown img,
.fr-popup .fr-command.fr-btn.fr-dropdown img {
  margin-left: 7.5px;
  margin-right: 15.5px; }

.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active {
  color: #222222;
  background: #d6d6d6; }

.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover,
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:focus,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:focus {
  background: #d6d6d6 !important;
  color: #222222 !important; }

.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:focus::after,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:focus::after {
  border-top-color: #222222 !important; }

.fr-toolbar .fr-command.fr-btn.fr-dropdown::after,
.fr-popup .fr-command.fr-btn.fr-dropdown::after {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #222222;
  right: 3.75px;
  top: 16.5px;
  content: ""; }

.fr-toolbar .fr-command.fr-btn.fr-disabled,
.fr-popup .fr-command.fr-btn.fr-disabled {
  color: #bdbdbd;
  cursor: default; }

.fr-toolbar .fr-command.fr-btn.fr-disabled::after,
.fr-popup .fr-command.fr-btn.fr-disabled::after {
  border-top-color: #bdbdbd !important; }

.fr-toolbar .fr-command.fr-btn.fr-hidden,
.fr-popup .fr-command.fr-btn.fr-hidden {
  display: none; }

.fr-toolbar.fr-disabled .fr-btn,
.fr-popup.fr-disabled .fr-btn,
.fr-toolbar.fr-disabled .fr-btn.fr-active,
.fr-popup.fr-disabled .fr-btn.fr-active {
  color: #bdbdbd; }

.fr-toolbar.fr-disabled .fr-btn.fr-dropdown::after,
.fr-popup.fr-disabled .fr-btn.fr-dropdown::after,
.fr-toolbar.fr-disabled .fr-btn.fr-active.fr-dropdown::after,
.fr-popup.fr-disabled .fr-btn.fr-active.fr-dropdown::after {
  border-top-color: #bdbdbd; }

.fr-toolbar.fr-rtl .fr-command.fr-btn,
.fr-popup.fr-rtl .fr-command.fr-btn {
  float: right; }

.fr-toolbar.fr-inline .fr-command.fr-btn {
  float: none; }

.fr-desktop .fr-command:hover,
.fr-desktop .fr-command:focus {
  color: #222222;
  background: #ebebeb; }

.fr-desktop .fr-command:hover::after,
.fr-desktop .fr-command:focus::after {
  border-top-color: #222222 !important; }

.fr-desktop .fr-command.fr-selected {
  color: #222222;
  background: #d6d6d6; }

.fr-desktop .fr-command.fr-active:hover,
.fr-desktop .fr-command.fr-active:focus {
  color: #42a5f5;
  background: #ebebeb; }

.fr-desktop .fr-command.fr-active.fr-selected {
  color: #42a5f5;
  background: #d6d6d6; }

.fr-desktop .fr-command.fr-disabled:hover,
.fr-desktop .fr-command.fr-disabled:focus,
.fr-desktop .fr-command.fr-disabled.fr-selected {
  background: transparent; }

.fr-desktop.fr-disabled .fr-command:hover,
.fr-desktop.fr-disabled .fr-command:focus,
.fr-desktop.fr-disabled .fr-command.fr-selected {
  background: transparent; }

.fr-toolbar.fr-mobile .fr-command.fr-blink,
.fr-popup.fr-mobile .fr-command.fr-blink {
  background: transparent; }

.fr-command.fr-btn + .fr-dropdown-menu {
  display: inline-block;
  position: absolute;
  right: auto;
  bottom: auto;
  height: auto;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  z-index: 3;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -webkit-border-radius: 0 0 2px 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper {
  background: #ffffff;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  padding: 0;
  margin: auto;
  display: inline-block;
  text-align: left;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: max-height 0.2s ease 0s;
  -moz-transition: max-height 0.2s ease 0s;
  -ms-transition: max-height 0.2s ease 0s;
  -o-transition: max-height 0.2s ease 0s;
  margin-top: 0;
  float: left;
  max-height: 0;
  height: 0;
  margin-top: 0 !important; }

.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content {
  overflow: auto;
  position: relative;
  max-height: 275px; }

.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li {
  padding: 0;
  margin: 0;
  font-size: 15px; }

.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a {
  padding: 0 24px;
  line-height: 200%;
  display: block;
  cursor: pointer;
  white-space: nowrap;
  color: inherit;
  text-decoration: none; }

.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active {
  background: #d6d6d6; }

.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-disabled {
  color: #bdbdbd;
  cursor: default; }

.fr-command.fr-btn.fr-active + .fr-dropdown-menu {
  display: inline-block; }

.fr-command.fr-btn.fr-active + .fr-dropdown-menu .fr-dropdown-wrapper {
  height: auto;
  max-height: 275px; }

.fr-bottom > .fr-command.fr-btn + .fr-dropdown-menu {
  border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

.fr-toolbar.fr-rtl .fr-dropdown-wrapper,
.fr-popup.fr-rtl .fr-dropdown-wrapper {
  text-align: right !important; }

.fr-popup {
  position: absolute;
  display: none;
  color: #222222;
  background: #ffffff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin-top: 10px;
  z-index: 9995;
  text-align: left;
  border: 0px;
  border-top: 3px solid #666666; }

.fr-popup.fr-above {
  margin-top: -10px;
  border-top: 0;
  border-bottom: 3px solid #666666; }

.fr-popup.fr-active {
  display: block; }

.fr-popup.fr-hidden {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }

.fr-popup .fr-hs {
  display: block !important; }

.fr-popup .fr-hs.fr-hidden {
  display: none !important; }

.fr-popup .fr-input-line {
  position: relative;
  padding: 8px 0; }

.fr-popup .fr-input-line input[type="text"],
.fr-popup .fr-input-line textarea {
  width: 100%;
  margin: 0px 0 1px 0;
  border: none;
  border-bottom: solid 1px #bdbdbd;
  color: #222222;
  font-size: 14px;
  padding: 6px 0 2px;
  background: transparent;
  position: relative;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.fr-popup .fr-input-line input[type="text"]:focus,
.fr-popup .fr-input-line textarea:focus {
  border-bottom: solid 2px #42a5f5;
  margin-bottom: 0px; }

.fr-popup .fr-input-line input + label,
.fr-popup .fr-input-line textarea + label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  color: transparent;
  -webkit-transition: color 0.2s ease 0s;
  -moz-transition: color 0.2s ease 0s;
  -ms-transition: color 0.2s ease 0s;
  -o-transition: color 0.2s ease 0s;
  z-index: 1; }

.fr-popup .fr-input-line input.fr-not-empty:focus + label,
.fr-popup .fr-input-line textarea.fr-not-empty:focus + label {
  color: #42a5f5; }

.fr-popup .fr-input-line input.fr-not-empty + label,
.fr-popup .fr-input-line textarea.fr-not-empty + label {
  color: #808080; }

.fr-popup input,
.fr-popup textarea {
  user-select: text;
  -o-user-select: text;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  outline: none; }

.fr-popup textarea {
  resize: none; }

.fr-popup .fr-buttons {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 0 2px;
  white-space: nowrap;
  line-height: 0;
  border-bottom: 0px; }

.fr-popup .fr-buttons::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.fr-popup .fr-buttons .fr-btn {
  display: inline-block;
  float: none; }

.fr-popup .fr-buttons .fr-btn i {
  float: left; }

.fr-popup .fr-buttons .fr-separator {
  display: inline-block;
  float: none; }

.fr-popup .fr-layer {
  width: 225px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 10px;
  display: none; }

@media (min-width: 768px) {
  .fr-popup .fr-layer {
    width: 300px; } }

.fr-popup .fr-layer.fr-active {
  display: inline-block; }

.fr-popup .fr-action-buttons {
  z-index: 7;
  height: 36px;
  text-align: right; }

.fr-popup .fr-action-buttons button.fr-command {
  height: 36px;
  line-height: 1;
  color: #42a5f5;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  background: none;
  font-size: 16px;
  outline: none;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
  font-weight: 700;
  font-size: 13px; }

.fr-popup .fr-action-buttons button.fr-command + button {
  margin-left: 24px; }

.fr-popup .fr-action-buttons button.fr-command:hover,
.fr-popup .fr-action-buttons button.fr-command:focus {
  background: #ebebeb;
  color: #42a5f5; }

.fr-popup .fr-action-buttons button.fr-command:active {
  background: #d6d6d6;
  color: #42a5f5; }

.fr-popup .fr-action-buttons button::-moz-focus-inner {
  border: 0; }

.fr-popup .fr-checkbox {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 1;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  vertical-align: middle; }

.fr-popup .fr-checkbox svg {
  margin-left: 2px;
  margin-top: 2px;
  display: none;
  width: 10px;
  height: 10px; }

.fr-popup .fr-checkbox span {
  border: solid 1px #666;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, border-color 0.2s ease 0s; }

.fr-popup .fr-checkbox input {
  position: absolute;
  z-index: 2;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border: 0 none;
  cursor: pointer;
  height: 16px;
  margin: 0;
  padding: 0;
  width: 16px;
  top: 1px;
  left: 1px; }

.fr-popup .fr-checkbox input:checked + span {
  background: #42a5f5;
  border-color: #42a5f5; }

.fr-popup .fr-checkbox input:checked + span svg {
  display: block; }

.fr-popup .fr-checkbox input:focus + span {
  border-color: #42a5f5; }

.fr-popup .fr-checkbox-line {
  font-size: 14px;
  line-height: 1.4px;
  margin-top: 10px; }

.fr-popup .fr-checkbox-line label {
  cursor: pointer;
  margin: 0 5px;
  vertical-align: middle;
  color: #777;
  font-size: 13px; }

.fr-popup.fr-rtl {
  direction: rtl;
  text-align: right; }

.fr-popup.fr-rtl .fr-action-buttons {
  text-align: left; }

.fr-popup.fr-rtl .fr-input-line input + label,
.fr-popup.fr-rtl .fr-input-line textarea + label {
  left: auto;
  right: 0; }

.fr-popup .fr-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #666666;
  position: absolute;
  top: -8px;
  left: 50%;
  margin-left: -5px;
  display: inline-block; }

.fr-popup.fr-above .fr-arrow {
  top: auto;
  bottom: -8px;
  border-bottom: 0;
  border-top: 5px solid #666666; }

.fr-text-edit-layer {
  width: 250px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block !important; }

.fr-visibility-helper {
  display: none;
  margin-left: 0px !important; }

@media (min-width: 768px) {
  .fr-visibility-helper {
    margin-left: 1px !important; } }

@media (min-width: 992px) {
  .fr-visibility-helper {
    margin-left: 2px !important; } }

@media (min-width: 1200px) {
  .fr-visibility-helper {
    margin-left: 3px !important; } }

.fr-opacity-0 {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }

.fr-box {
  position: relative; }

/**
 * Postion sticky hacks.
 */
.fr-sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky; }

.fr-sticky-off {
  position: relative; }

.fr-sticky-on {
  position: fixed; }

.fr-sticky-on.fr-sticky-ios {
  position: absolute;
  left: 0;
  right: 0;
  width: auto !important; }

.fr-sticky-dummy {
  display: none; }

.fr-sticky-on + .fr-sticky-dummy,
.fr-sticky-box > .fr-sticky-dummy {
  display: block; }

.fr-placeholder {
  font-size: 14px !important; }

/*
** Overrides
*/
.fr-box {
  margin-bottom: 4px; }

.fr-toolbar h1,
.fr-toolbar h2,
.fr-toolbar h3,
.fr-toolbar h4,
.fr-toolbar h5,
.fr-toolbar h6,
.fr-popup h1,
.fr-popup h2,
.fr-popup h3,
.fr-popup h4,
.fr-popup h5,
.fr-popup h6 {
  font-weight: normal;
  font-size: inherit; }

.fr-toolbar .icon,
.fr-popup .icon {
  color: #6d6d6d; }

.fr-toolbar .fr-disabled .icon,
.fr-popup .fr-disabled .icon {
  color: #b2b2b2; }

.fr-toolbar .fr-btn:not(.fr-disabled):hover .icon,
.fr-popup .fr-btn:not(.fr-disabled):hover .icon {
  color: #000; }

.fr-toolbar .fr-btn.fr-active .icon,
.fr-popup .fr-btn.fr-active .icon {
  color: #C39A2A; }

.fr-toolbar .fr-dropdown,
.fr-popup .fr-dropdown {
  width: auto !important; }

.fr-toolbar .fr-dropdown-menu .icon,
.fr-popup .fr-dropdown-menu .icon {
  display: block;
  padding: 4px 0; }

.fr-toolbar .icon-fullscreen,
.fr-popup .icon-fullscreen {
  font-size: 29px !important;
  line-height: 29px !important;
  height: 29px !important; }

.fr-toolbar .fr-command.fr-btn,
.fr-popup .fr-command.fr-btn {
  height: 43px; }
  .fr-toolbar .fr-command.fr-btn span,
  .fr-popup .fr-command.fr-btn span {
    font-size: 23px;
    line-height: 23px;
    height: 23px;
    font-weight: normal; }
    .fr-toolbar .fr-command.fr-btn span.icon-paragraph,
    .fr-popup .fr-command.fr-btn span.icon-paragraph {
      font-size: 19px; }

.fr-toolbar .fr-command.fr-btn.fr-dropdown i,
.fr-popup .fr-command.fr-btn.fr-dropdown i,
.fr-toolbar .fr-command.fr-btn.fr-dropdown span,
.fr-popup .fr-command.fr-btn.fr-dropdown span,
.fr-toolbar .fr-command.fr-btn.fr-dropdown img,
.fr-popup .fr-command.fr-btn.fr-dropdown img {
  margin-left: 0; }

.fr-toolbar .fr-command.fr-btn.fr-dropdown::after,
.fr-popup .fr-command.fr-btn.fr-dropdown::after {
  top: 19.5px; }

.text-button {
  text-transform: uppercase;
  color: #FAFAFA !important;
  font-size: 15px;
  letter-spacing: 2px;
  font-weight: 400;
  display: inline-block; }
  .text-button:hover, .text-button:focus {
    color: #C39A2A;
    transition: .3s; }
  .text-button--line {
    padding: 8px 20px;
    position: relative;
    line-height: 1em;
    font-weight: 700 !important;
    border-top: 1px solid rgba(64, 61, 76, 0.75);
    border-bottom: 1px solid rgba(64, 61, 76, 0.75); }
    .text-button--line::after {
      content: '\e646';
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-left: 5px;
      position: relative;
      top: 1px;
      color: #C39A2A; }

.play-video {
  display: inline-block;
  width: 66px;
  height: 66px;
  background: url("https://static.neocoregames.com/frontend/app/assets/images/community/edit-post-video-play-button.png") no-repeat center center;
  background-size: cover;
  opacity: 0.8;
  transition: opacity .3s; }
  .play-video:hover {
    opacity: 1;
    transition: opacity .3s; }

.mt0 {
  margin-top: 0px !important; }

.mt5 {
  margin-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt30 {
  margin-top: 30px !important; }

.mt40 {
  margin-top: 40px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.read-more {
  text-transform: uppercase;
  display: block;
  width: 100%;
  float: none;
  clear: both;
  color: rgba(255, 255, 255, 0.6);
  position: relative;
  text-align: center;
  padding: 5px 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px; }
  .read-more::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    border-top: 2px dashed rgba(255, 255, 255, 0.3); }
  .read-more::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px dashed rgba(255, 255, 255, 0.3); }

.fr-box.fr-basic .fr-element {
  line-height: 140%;
  background: #666;
  padding: 20px;
  color: #eeeeee; }

.fr-element ul,
.fr-element ol,
ul.fr-tag,
ol.fr-tag {
  padding-left: 25px; }

.fr-element p,
p.fr-tag {
  margin: 0 0 5px; }

.fr-element h1,
h1[class="fr-tag"] {
  font-size: 36px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px; }

.fr-element h2,
h2[class="fr-tag"] {
  font-size: 30px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px; }

.fr-element h3,
h3[class="fr-tag"] {
  font-size: 24px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px; }

.fr-element h4,
h4[class="fr-tag"] {
  font-size: 18px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px; }

.fr-element h5,
h5[class="fr-tag"] {
  font-size: 14px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px; }

.fr-element h6,
h6[class="fr-tag"] {
  font-size: 12px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px; }

.fr-element a,
.fr-element .link {
  color: #C39A2A;
  text-decoration: none;
  font-weight: 500;
  transition: color .3s;
  cursor: pointer; }
  .fr-element a:hover,
  .fr-element .link:hover {
    color: #e1c372;
    transition: color .3s; }
  .fr-element a.link-blue,
  .fr-element .link.link-blue {
    color: #0091ea; }
    .fr-element a.link-blue:hover,
    .fr-element .link.link-blue:hover {
      color: #51bdff; }
  .fr-element a.link-underline,
  .fr-element .link.link-underline {
    text-decoration: underline; }

.fr-element table {
  border-collapse: collapse; }
  .fr-element table td {
    border: 1px dashed #474a51; }

.fr-element .style--lower-font-size {
  font-size: 13px; }

.fr-element .style--higher-font-size {
  font-size: 18px; }

.fr-element .style--default-color {
  color: #C39A2A; }

.fr-element .style--uppercase {
  text-transform: uppercase; }

.fr-element .style--lowercase {
  text-transform: none; }

.fr-element .style--bold {
  font-weight: 700; }

.fr-element .style--normal {
  font-weight: 400; }

.fr-element .style--italic {
  font-style: italic; }

.fr-element .style--align-center {
  text-align: center; }

.fr-element .style--gray {
  color: #eee; }

.fr-element .style--underline {
  text-decoration: underline; }

.fr-element .style--low-letterspacing {
  letter-spacing: 1px; }

.fr-element .style--medium-letterspacing {
  letter-spacing: 2px; }

.fr-element .style--high-letterspacing {
  letter-spacing: 3px; }

.fr-element .generic-title {
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  color: #eee; }
  .fr-element .generic-title.is-left {
    text-align: left; }
    .fr-element .generic-title.is-left::before {
      left: 0;
      margin-left: 0; }
  .fr-element .generic-title::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 130px;
    height: 1px;
    background: #C39A2A;
    margin-left: -65px; }
  .fr-element .generic-title--large {
    font-size: 36px;
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 30px;
    margin-bottom: 30px;
    letter-spacing: 6px; }

.fr-video {
  position: relative;
  text-align: center;
  display: inline-block;
  /*
  &.fr-dvb {
    display: inline-block;
  }
  &.fr-fvr {
    text-align: right;
  }
  &.fr-fvl {
    text-align: left;
  }
  */ }

.fr-video {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  display: block; }

.fr-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.custom-header-wrapper {
  padding-top: 200px;
  padding-bottom: 200px; }

.fr-wrapper blockquote {
  color: #f4f4f4;
  font-size: 25px;
  line-height: 31px;
  font-style: italic;
  font-weight: 300;
  position: relative;
  padding: 50px 54px;
  margin: 0;
  overflow: hidden; }
  .fr-wrapper blockquote:before, .fr-wrapper blockquote:after {
    color: #f4f4f4;
    font-size: 75px;
    line-height: 75px;
    display: block;
    position: absolute;
    font-family: 'Times New Roman', sans-serif;
    letter-spacing: 0; }
  .fr-wrapper blockquote:before {
    content: '“';
    top: 16px;
    left: -12px; }
  .fr-wrapper blockquote:after {
    content: '”';
    bottom: -14px;
    right: 0px; }

.CodeMirror {
  font-family: monospace;
  height: 300px;
  color: #000; }

.CodeMirror-lines {
  padding: 4px 0; }

.CodeMirror pre {
  padding: 0 4px; }

.CodeMirror-gutter-filler, .CodeMirror-scrollbar-filler {
  background-color: #fff; }

.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap; }

.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap; }

.CodeMirror-guttermarker {
  color: #000; }

.CodeMirror-guttermarker-subtle {
  color: #999; }

.CodeMirror div.CodeMirror-cursor {
  border-left: 1px solid #000; }

.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver; }

.CodeMirror.cm-fat-cursor div.CodeMirror-cursor {
  width: auto;
  border: 0;
  background: #7e7; }

.CodeMirror.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1; }

.cm-animate-fat-cursor {
  width: auto;
  border: 0;
  -webkit-animation: blink 1.06s steps(1) infinite;
  -moz-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite; }

@-moz-keyframes blink {
  0% {
    background: #7e7; }
  50% {
    background: 0 0; }
  100% {
    background: #7e7; } }

@-webkit-keyframes blink {
  0% {
    background: #7e7; }
  50% {
    background: 0 0; }
  100% {
    background: #7e7; } }

@keyframes blink {
  0% {
    background: #7e7; }
  50% {
    background: 0 0; }
  100% {
    background: #7e7; } }

.cm-tab {
  display: inline-block;
  text-decoration: inherit; }

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  position: absolute; }

.cm-s-default .cm-keyword {
  color: #708; }

.cm-s-default .cm-atom {
  color: #219; }

.cm-s-default .cm-number {
  color: #164; }

.cm-s-default .cm-def {
  color: #00f; }

.cm-s-default .cm-variable-2 {
  color: #05a; }

.cm-s-default .cm-variable-3 {
  color: #085; }

.cm-s-default .cm-comment {
  color: #a50; }

.cm-s-default .cm-string {
  color: #a11; }

.cm-s-default .cm-string-2 {
  color: #f50; }

.cm-s-default .cm-meta, .cm-s-default .cm-qualifier {
  color: #555; }

.cm-s-default .cm-builtin {
  color: #30a; }

.cm-s-default .cm-bracket {
  color: #997; }

.cm-s-default .cm-tag {
  color: #170; }

.cm-s-default .cm-attribute {
  color: #00c; }

.cm-s-default .cm-header {
  color: #00f; }

.cm-s-default .cm-quote {
  color: #090; }

.cm-s-default .cm-hr {
  color: #999; }

.cm-s-default .cm-link {
  color: #00c; }

.cm-negative {
  color: #d44; }

.cm-positive {
  color: #292; }

.cm-header, .cm-strong {
  font-weight: 700; }

.cm-em {
  font-style: italic; }

.cm-link {
  text-decoration: underline; }

.cm-strikethrough {
  text-decoration: line-through; }

.cm-invalidchar, .cm-s-default .cm-error {
  color: red; }

.CodeMirror-composing {
  border-bottom: 2px solid; }

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0f0; }

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #f22; }

.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3); }

.CodeMirror-activeline-background {
  background: #e8f2ff; }

.CodeMirror {
  position: relative;
  overflow: hidden;
  background: #fff; }

.CodeMirror-scroll {
  overflow: scroll !important;
  margin-bottom: -30px;
  margin-right: -30px;
  padding-bottom: 30px;
  height: 100%;
  outline: 0;
  position: relative; }

.CodeMirror-sizer {
  position: relative;
  border-right: 30px solid transparent; }

.CodeMirror-gutter-filler, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-vscrollbar {
  position: absolute;
  z-index: 6;
  display: none; }

.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll; }

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll; }

.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0; }

.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0; }

.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3; }

.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  margin-bottom: -30px; }

.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  height: 100%; }

.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4; }

.CodeMirror-gutter-wrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.CodeMirror-lines {
  cursor: text;
  min-height: 1px; }

.CodeMirror pre {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0;
  background: 0 0;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent; }

.CodeMirror-wrap pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal; }

.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0; }

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  overflow: auto; }

.CodeMirror-code {
  outline: 0; }

.CodeMirror-gutter, .CodeMirror-gutters, .CodeMirror-linenumber, .CodeMirror-scroll, .CodeMirror-sizer {
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden; }

.CodeMirror-measure pre {
  position: static; }

.CodeMirror div.CodeMirror-cursor {
  position: absolute;
  border-right: none;
  width: 0; }

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3; }

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible; }

.CodeMirror-selected {
  background: #d9d9d9; }

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0; }

.CodeMirror-crosshair {
  cursor: crosshair; }

.CodeMirror ::selection {
  background: #d7d4f0; }

.CodeMirror ::-moz-selection {
  background: #d7d4f0; }

.cm-searching {
  background: #ffa;
  background: rgba(255, 255, 0, 0.4); }

.cm-force-border {
  padding-right: .1px; }

@media print {
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden; } }

.cm-tab-wrap-hack:after {
  content: ''; }

span.CodeMirror-selectedtext {
  background: 0 0; }

/*!
 * froala_editor v2.3.5 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2016 Froala Labs
 */
.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

textarea.fr-code {
  display: none;
  width: 100%;
  resize: none;
  -moz-resize: none;
  -webkit-resize: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 0;
  padding: 10px;
  margin: 0;
  font-family: "Courier New",monospace;
  font-size: 14px;
  background: #fff;
  color: #000;
  outline: 0; }

.fr-box.fr-rtl textarea.fr-code {
  direction: rtl; }

.fr-box .CodeMirror {
  display: none; }

.fr-box.fr-code-view textarea.fr-code {
  display: block; }

.fr-box.fr-code-view.fr-inline {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

.fr-box.fr-code-view .fr-element, .fr-box.fr-code-view .fr-placeholder, .fr-box.fr-code-view .fr-iframe {
  display: none; }

.fr-box.fr-code-view .CodeMirror {
  display: block; }

.fr-box.fr-inline.fr-code-view .fr-command.fr-btn.html-switch {
  display: block; }

.fr-box.fr-inline .fr-command.fr-btn.html-switch {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: none;
  background: #fff;
  color: #222;
  -moz-outline: 0;
  outline: 0;
  border: 0;
  line-height: 1;
  cursor: pointer;
  text-align: left;
  padding: 11px 11.5px;
  -webkit-transition: background .2s ease 0s;
  -moz-transition: background .2s ease 0s;
  -ms-transition: background .2s ease 0s;
  -o-transition: background .2s ease 0s;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

.fr-box.fr-inline .fr-command.fr-btn.html-switch i {
  font-size: 15px;
  width: 15px;
  text-align: center; }

.fr-box.fr-inline .fr-command.fr-btn.html-switch.fr-desktop:hover {
  background: #ebebeb; }

/*!
 * froala_editor v2.3.5 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2016 Froala Labs
 */
.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.fr-popup .fr-colors-tabs {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 5px;
  line-height: 16px;
  margin-left: -2px;
  margin-right: -2px; }

.fr-popup .fr-colors-tabs .fr-colors-tab {
  display: inline-block;
  width: 50%;
  cursor: pointer;
  text-align: center;
  color: #222;
  font-size: 13px;
  padding: 8px 0;
  position: relative; }

.fr-popup .fr-colors-tabs .fr-colors-tab:hover {
  color: #1e88e5; }

.fr-popup .fr-colors-tabs .fr-colors-tab[data-param1=background]::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #1e88e5;
  content: '';
  -webkit-transition: transform .2s ease 0s;
  -moz-transition: transform .2s ease 0s;
  -ms-transition: transform .2s ease 0s;
  -o-transition: transform .2s ease 0s; }

.fr-popup .fr-colors-tabs .fr-colors-tab.fr-selected-tab {
  color: #1e88e5; }

.fr-popup .fr-colors-tabs .fr-colors-tab.fr-selected-tab[data-param1=text] ~ [data-param1=background]::after {
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  -o-transform: translate3d(-100%, 0, 0); }

.fr-popup .fr-separator + .fr-colors-tabs {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-left: 2px;
  margin-right: 2px; }

.fr-popup .fr-color-set {
  line-height: 0;
  display: none; }

.fr-popup .fr-color-set.fr-selected-set {
  display: block; }

.fr-popup .fr-color-set > span {
  display: inline-block;
  width: 32px;
  height: 32px;
  position: relative;
  z-index: 1; }

.fr-popup .fr-color-set > span > i {
  text-align: center;
  line-height: 32px;
  height: 32px;
  width: 32px;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  cursor: default;
  left: 0; }

.fr-popup .fr-color-set > span.fr-selected-color::after {
  color: #fff;
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  cursor: default; }

.fr-popup .fr-color-set > span:hover, .fr-popup .fr-color-set > span.fr-selected-color {
  outline: 1px solid #222;
  z-index: 2; }

.fr-rtl .fr-popup .fr-colors-tabs .fr-colors-tab.fr-selected-tab[data-param1=text] ~ [data-param1=background]::after {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0); }

/*!
 * froala_editor v2.3.5 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2016 Froala Labs
 */
body.fr-fullscreen {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: fixed; }

.fr-box.fr-fullscreen {
  margin: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9990 !important;
  width: auto !important; }

.fr-box.fr-fullscreen .fr-toolbar.fr-top {
  top: 0 !important; }

.fr-box.fr-fullscreen .fr-toolbar.fr-bottom {
  bottom: 0 !important; }

/*!
 * froala_editor v2.3.5 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2016 Froala Labs
 */
.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.fr-element img {
  cursor: pointer; }

.fr-image-resizer {
  position: absolute;
  border: solid 1px #1e88e5;
  display: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.fr-image-resizer.fr-active {
  display: block; }

.fr-image-resizer .fr-handler {
  display: block;
  position: absolute;
  background: #1e88e5;
  border: solid 1px #fff;
  z-index: 4;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.fr-image-resizer .fr-handler.fr-hnw {
  cursor: nw-resize; }

.fr-image-resizer .fr-handler.fr-hne {
  cursor: ne-resize; }

.fr-image-resizer .fr-handler.fr-hsw {
  cursor: sw-resize; }

.fr-image-resizer .fr-handler.fr-hse {
  cursor: se-resize; }

.fr-image-resizer .fr-handler {
  width: 12px;
  height: 12px; }

.fr-image-resizer .fr-handler.fr-hnw {
  left: -6px;
  top: -6px; }

.fr-image-resizer .fr-handler.fr-hne {
  right: -6px;
  top: -6px; }

.fr-image-resizer .fr-handler.fr-hsw {
  left: -6px;
  bottom: -6px; }

.fr-image-resizer .fr-handler.fr-hse {
  right: -6px;
  bottom: -6px; }

@media (min-width: 1200px) {
  .fr-image-resizer .fr-handler {
    width: 10px;
    height: 10px; }
  .fr-image-resizer .fr-handler.fr-hnw {
    left: -5px;
    top: -5px; }
  .fr-image-resizer .fr-handler.fr-hne {
    right: -5px;
    top: -5px; }
  .fr-image-resizer .fr-handler.fr-hsw {
    left: -5px;
    bottom: -5px; }
  .fr-image-resizer .fr-handler.fr-hse {
    right: -5px;
    bottom: -5px; } }

.fr-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  display: none; }

.fr-image-upload-layer {
  border: dashed 2px #bdbdbd;
  padding: 25px 0;
  position: relative;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 140%;
  text-align: center; }

.fr-image-upload-layer:hover {
  background: #ebebeb; }

.fr-image-upload-layer.fr-drop {
  background: #ebebeb;
  border-color: #1e88e5; }

.fr-image-upload-layer .fr-form {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "alpha(Opacity=0)";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important; }

.fr-image-upload-layer .fr-form input {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 500%;
  height: 100%;
  margin: 0;
  font-size: 400px; }

.fr-image-progress-bar-layer > h3 {
  font-size: 16px;
  margin: 10px 0;
  font-weight: 400; }

.fr-image-progress-bar-layer > div.fr-action-buttons {
  display: none; }

.fr-image-progress-bar-layer > div.fr-loader {
  background: #bcdbf7;
  height: 10px;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
  position: relative; }

.fr-image-progress-bar-layer > div.fr-loader span {
  display: block;
  height: 100%;
  width: 0;
  background: #1e88e5;
  -webkit-transition: width .2s ease 0s;
  -moz-transition: width .2s ease 0s;
  -ms-transition: width .2s ease 0s;
  -o-transition: width .2s ease 0s; }

.fr-image-progress-bar-layer > div.fr-loader.fr-indeterminate span {
  width: 30% !important;
  position: absolute;
  top: 0;
  -webkit-animation: loading 2s linear infinite;
  -moz-animation: loading 2s linear infinite;
  -o-animation: loading 2s linear infinite;
  animation: loading 2s linear infinite; }

.fr-image-progress-bar-layer.fr-error > div.fr-loader {
  display: none; }

.fr-image-progress-bar-layer.fr-error > div.fr-action-buttons {
  display: block; }

.fr-image-size-layer .fr-image-group .fr-input-line {
  width: calc(50% - 5px);
  display: inline-block; }

.fr-image-size-layer .fr-image-group .fr-input-line + .fr-input-line {
  margin-left: 10px; }

.fr-uploading {
  -webkit-opacity: .4;
  -moz-opacity: .4;
  opacity: .4;
  -ms-filter: "alpha(Opacity=0)"; }

@keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }

@-webkit-keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }

@-moz-keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }

@-o-keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }

/*!
 * froala_editor v2.3.5 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2016 Froala Labs
 */
.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.fr-line-breaker {
  cursor: text;
  border-top: 1px solid #1e88e5;
  position: fixed;
  z-index: 2;
  display: none; }

.fr-line-breaker.fr-visible {
  display: block; }

.fr-line-breaker a.fr-floating-btn {
  position: absolute;
  left: calc(50% - (32px / 2));
  top: -16px; }

/*!
 * froala_editor v2.3.5 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2016 Froala Labs
 */
.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.fr-element table td.fr-selected-cell, .fr-element table th.fr-selected-cell {
  border: 1px double #1e88e5; }

.fr-element table tr {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

.fr-element table td, .fr-element table th {
  user-select: text;
  -o-user-select: text;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text; }

.fr-element .fr-no-selection table td, .fr-element .fr-no-selection table th {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

.fr-table-resizer {
  cursor: col-resize;
  position: fixed;
  z-index: 3;
  display: none; }

.fr-table-resizer.fr-moving {
  z-index: 2; }

.fr-table-resizer div {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "alpha(Opacity=0)";
  border-right: 1px solid #1e88e5; }

.fr-no-selection {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

.fr-popup .fr-table-size .fr-table-size-info {
  text-align: center;
  font-size: 14px;
  padding: 8px; }

.fr-popup .fr-table-size .fr-select-table-size {
  line-height: 0;
  padding: 0 5px 5px;
  white-space: nowrap; }

.fr-popup .fr-table-size .fr-select-table-size > span {
  display: inline-block;
  padding: 0 4px 4px 0; }

.fr-popup .fr-table-size .fr-select-table-size > span > span {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd; }

.fr-popup .fr-table-size .fr-select-table-size > span:hover, .fr-popup .fr-table-size .fr-select-table-size > span.hover {
  background: 0 0; }

.fr-popup .fr-table-size .fr-select-table-size > span:hover > span, .fr-popup .fr-table-size .fr-select-table-size > span.hover > span {
  background: rgba(30, 136, 229, 0.3);
  border: solid 1px #1e88e5; }

.fr-popup .fr-table-size .fr-select-table-size .new-line::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.fr-popup.fr-above .fr-table-size .fr-select-table-size > span {
  display: inline-block !important; }

.fr-popup .fr-table-colors-buttons {
  margin-bottom: 5px; }

.fr-popup .fr-table-colors {
  line-height: 0;
  display: block; }

.fr-popup .fr-table-colors > span {
  display: inline-block;
  width: 32px;
  height: 32px;
  position: relative;
  z-index: 1; }

.fr-popup .fr-table-colors > span > i {
  text-align: center;
  line-height: 32px;
  height: 32px;
  width: 32px;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  cursor: default;
  left: 0; }

.fr-popup .fr-table-colors > span.fr-selected-color::after {
  color: #fff;
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  cursor: default; }

.fr-popup .fr-table-colors > span:hover, .fr-popup .fr-table-colors > span.fr-selected-color {
  outline: 1px solid #222;
  z-index: 2; }

.fr-popup.fr-desktop .fr-table-size .fr-select-table-size > span > span {
  width: 12px;
  height: 12px; }

.fr-insert-helper {
  position: fixed;
  z-index: 9999;
  white-space: nowrap; }

[style="position: absolute; bottom: 0px; left: 0px; z-index: 9999;"] {
  display: none !important; }
