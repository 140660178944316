/*!
 * froala_editor v2.3.4 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2016 Froala Labs
 */

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.fr-element,
.fr-element:focus {
  outline: 0px solid transparent;
}
.fr-box.fr-basic .fr-element {
  text-align: initial;
  color: #000000;
  padding: 10px 0 0 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: auto;
  min-height: 40px;
}
.fr-element {
  background: transparent;
  position: relative;
  z-index: 2;
  -webkit-user-select: auto;
}
.fr-element a {
  user-select: auto;
  -o-user-select: auto;
  -moz-user-select: auto;
  -khtml-user-select: auto;
  -webkit-user-select: auto;
  -ms-user-select: auto;
}
.fr-element.fr-disabled {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.fr-element [contenteditable="false"] {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.fr-element [contenteditable="true"] {
  outline: 0px solid transparent;
}
.fr-box a.fr-floating-btn {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  height: 32px;
  width: 32px;
  background: #ffffff;
  color: rgb(66,165,245);
  -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  outline: none;
  left: 0;
  top: 0;
  line-height: 32px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  text-align: center;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
}
.fr-box a.fr-floating-btn svg {
  -webkit-transition: transform 0.2s ease 0s;
  -moz-transition: transform 0.2s ease 0s;
  -ms-transition: transform 0.2s ease 0s;
  -o-transition: transform 0.2s ease 0s;
  fill: rgb(66,165,245);
}
.fr-box a.fr-floating-btn i {
  font-size: 14px;
  line-height: 32px;
}
.fr-box a.fr-floating-btn.fr-btn + .fr-btn {
  margin-left: 10px;
}
.fr-box a.fr-floating-btn:hover {
  background: #ebebeb;
  cursor: pointer;
}
.fr-box a.fr-floating-btn:hover svg {
  fill: rgb(66,165,245);
}
.fr-box .fr-visible a.fr-floating-btn {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}
iframe.fr-iframe {
  width: 100%;
  border: none;
  position: relative;
  display: block;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.fr-wrapper {
  position: relative;
  z-index: 1;
}
.fr-wrapper::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.fr-wrapper .fr-placeholder {
  position: absolute;
  font-size: 12px;
  color: #FAFAFA;
  z-index: 2;
  display: none;
  top: 0;
  left: 0;
  overflow: hidden;
}
.fr-wrapper.show-placeholder .fr-placeholder {
  display: block;
}
.fr-wrapper ::selection {
  background: #b5d6fd;
  color: #000000;
}
.fr-wrapper ::-moz-selection {
  background: #b5d6fd;
  color: #000000;
}
.fr-box.fr-rtl .fr-wrapper .fr-placeholder {
  right: 0;
  left: auto;
}
.fr-box.fr-basic .fr-wrapper {
  background: #ffffff;
  border: 0px;
  border-top: 0;
  top: 0;
  left: 0;
}
.fr-box.fr-basic.fr-rtl .fr-wrapper .fr-placeholder {
  right: 0;
  left: auto;
}
.fr-box.fr-basic.fr-top .fr-wrapper {
  border-top: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.fr-box.fr-basic.fr-bottom .fr-wrapper {
  border-bottom: 0;
  border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  
}
.fr-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 8px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  background: #222222;
  color: #ffffff;
  font-size: 11px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  left: -3000px;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  z-index: 9997;
}
.fr-tooltip.fr-visible {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.fr-toolbar {
  color: #222222;
  background: #f1f1f1;
  position: relative;
  z-index: 4;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  text-align: left;
  border: 1px solid #ccc;
}
.fr-toolbar::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.fr-toolbar.fr-rtl {
  text-align: right;
}
.fr-toolbar.fr-inline {
  display: none;
  white-space: nowrap;
  position: absolute;
  margin-top: 10px;
}
.fr-toolbar.fr-inline .fr-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #222222;
  position: absolute;
  top: -9px;
  left: 50%;
  margin-left: -5px;
  display: inline-block;
}
.fr-toolbar.fr-inline.fr-above {
  margin-top: -10px;
  
  border-bottom: 5px solid #222222;
  border-top: 0;
}
.fr-toolbar.fr-inline.fr-above .fr-arrow {
  top: auto;
  bottom: -9px;
  border-bottom: 0;
  border-top-color: inherit;
  border-top-style: solid;
  border-top-width: 5px;
}
.fr-toolbar.fr-top {
  top: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.fr-toolbar.fr-bottom {
  bottom: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  padding-left: 5px;
  padding-right: 5px;
}
.fr-separator {
  background: #ebebeb;
  display: block;
  vertical-align: top;
  float: left;
}
.fr-separator + .fr-separator {
  display: none;
}
.fr-separator.fr-vs {
  height: 33px;
  width: 1px;
  margin: 2px;
}
.fr-separator.fr-hs {
  clear: both;
  height: 1px;
  width: calc(100% - (2 * 2px));
  margin: 0 2px;
}
.fr-separator.fr-hidden {
  display: none !important;
}
.fr-rtl .fr-separator {
  float: right;
}
.fr-toolbar.fr-inline .fr-separator.fr-hs {
  float: none;
}
.fr-toolbar.fr-inline .fr-separator.fr-vs {
  float: none;
  display: inline-block;
}
.fr-toolbar .fr-command.fr-btn,
.fr-popup .fr-command.fr-btn {
  background: transparent;
  color: #707070;
  -moz-outline: 0;
  outline: 0;
  border: 0;
  line-height: 1;
  cursor: pointer;
  text-align: left;
  margin: 0px 2px;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  z-index: 2;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  float: left;
  padding: 0;
  width: 30px;
  height: 37px;
  text-align: center;
}
.fr-toolbar .fr-command.fr-btn i,
.fr-popup .fr-command.fr-btn i {
  display: inline-block;
  font-size: 20px;
  width: 19px;
  margin: 9px 5px;
  text-align: center;
  float: none;
  // height: 37px;
  // line-height: 39px;
}
.fr-toolbar .fr-command.fr-btn span,
.fr-popup .fr-command.fr-btn span {
  font-size: 14px;
  display: block;
  line-height: 14px;
  min-width: 38px;
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 15px;
  font-weight: bold;
}
.fr-toolbar .fr-command.fr-btn img,
.fr-popup .fr-command.fr-btn img {
  margin: 11px 11.5px;
  width: 15px;
}
.fr-toolbar .fr-command.fr-btn.fr-active,
.fr-popup .fr-command.fr-btn.fr-active {
  color: rgb(66,165,245);
  background: transparent;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-selection,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-selection {
  width: auto;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-selection span,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-selection span {
  font-weight: normal;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown i,
.fr-popup .fr-command.fr-btn.fr-dropdown i,
.fr-toolbar .fr-command.fr-btn.fr-dropdown span,
.fr-popup .fr-command.fr-btn.fr-dropdown span,
.fr-toolbar .fr-command.fr-btn.fr-dropdown img,
.fr-popup .fr-command.fr-btn.fr-dropdown img {
  margin-left: 7.5px;
  margin-right: 15.5px;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active {
  color: #222222;
  background: #d6d6d6;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover,
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:focus,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:focus {
  background: #d6d6d6 !important;
  color: #222222 !important;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:focus::after,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:focus::after {
  border-top-color: #222222 !important;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown::after,
.fr-popup .fr-command.fr-btn.fr-dropdown::after {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #222222;
  right: 3.75px;
  top: 16.5px;
  content: "";
}
.fr-toolbar .fr-command.fr-btn.fr-disabled,
.fr-popup .fr-command.fr-btn.fr-disabled {
  color: #bdbdbd;
  cursor: default;
}
.fr-toolbar .fr-command.fr-btn.fr-disabled::after,
.fr-popup .fr-command.fr-btn.fr-disabled::after {
  border-top-color: #bdbdbd !important;
}
.fr-toolbar .fr-command.fr-btn.fr-hidden,
.fr-popup .fr-command.fr-btn.fr-hidden {
  display: none;
}
.fr-toolbar.fr-disabled .fr-btn,
.fr-popup.fr-disabled .fr-btn,
.fr-toolbar.fr-disabled .fr-btn.fr-active,
.fr-popup.fr-disabled .fr-btn.fr-active {
  color: #bdbdbd;
}
.fr-toolbar.fr-disabled .fr-btn.fr-dropdown::after,
.fr-popup.fr-disabled .fr-btn.fr-dropdown::after,
.fr-toolbar.fr-disabled .fr-btn.fr-active.fr-dropdown::after,
.fr-popup.fr-disabled .fr-btn.fr-active.fr-dropdown::after {
  border-top-color: #bdbdbd;
}
.fr-toolbar.fr-rtl .fr-command.fr-btn,
.fr-popup.fr-rtl .fr-command.fr-btn {
  float: right;
}
.fr-toolbar.fr-inline .fr-command.fr-btn {
  float: none;
}
.fr-desktop .fr-command:hover,
.fr-desktop .fr-command:focus {
  color: #222222;
  background: #ebebeb;
}
.fr-desktop .fr-command:hover::after,
.fr-desktop .fr-command:focus::after {
  border-top-color: #222222 !important;
}
.fr-desktop .fr-command.fr-selected {
  color: #222222;
  background: #d6d6d6;
}
.fr-desktop .fr-command.fr-active:hover,
.fr-desktop .fr-command.fr-active:focus {
  color: rgb(66,165,245);
  background: #ebebeb;
}
.fr-desktop .fr-command.fr-active.fr-selected {
  color: rgb(66,165,245);
  background: #d6d6d6;
}
.fr-desktop .fr-command.fr-disabled:hover,
.fr-desktop .fr-command.fr-disabled:focus,
.fr-desktop .fr-command.fr-disabled.fr-selected {
  background: transparent;
}
.fr-desktop.fr-disabled .fr-command:hover,
.fr-desktop.fr-disabled .fr-command:focus,
.fr-desktop.fr-disabled .fr-command.fr-selected {
  background: transparent;
}
.fr-toolbar.fr-mobile .fr-command.fr-blink,
.fr-popup.fr-mobile .fr-command.fr-blink {
  background: transparent;
}
.fr-command.fr-btn + .fr-dropdown-menu {
  display: inline-block;
  position: absolute;
  right: auto;
  bottom: auto;
  height: auto;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  z-index: 3;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -webkit-border-radius: 0 0 2px 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper {
  background: #ffffff;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  padding: 0;
  margin: auto;
  display: inline-block;
  text-align: left;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: max-height 0.2s ease 0s;
  -moz-transition: max-height 0.2s ease 0s;
  -ms-transition: max-height 0.2s ease 0s;
  -o-transition: max-height 0.2s ease 0s;
  margin-top: 0;
  float: left;
  max-height: 0;
  height: 0;
  margin-top: 0 !important;
}
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content {
  overflow: auto;
  position: relative;
  max-height: 275px;
}
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li {
  padding: 0;
  margin: 0;
  font-size: 15px;
}
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a {
  padding: 0 24px;
  line-height: 200%;
  display: block;
  cursor: pointer;
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
}
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active {
  background: #d6d6d6;
}
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-disabled {
  color: #bdbdbd;
  cursor: default;
}
.fr-command.fr-btn.fr-active + .fr-dropdown-menu {
  display: inline-block;
}
.fr-command.fr-btn.fr-active + .fr-dropdown-menu .fr-dropdown-wrapper {
  height: auto;
  max-height: 275px;
}
.fr-bottom > .fr-command.fr-btn + .fr-dropdown-menu {
  border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.fr-toolbar.fr-rtl .fr-dropdown-wrapper,
.fr-popup.fr-rtl .fr-dropdown-wrapper {
  text-align: right !important;
}
.fr-popup {
  position: absolute;
  display: none;
  color: #222222;
  background: #ffffff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin-top: 10px;
  z-index: 9995;
  text-align: left;
  border: 0px;
  border-top: 3px solid #666666;
}
.fr-popup.fr-above {
  margin-top: -10px;
  border-top: 0;
  border-bottom: 3px solid #666666;
  
}
.fr-popup.fr-active {
  display: block;
}
.fr-popup.fr-hidden {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.fr-popup .fr-hs {
  display: block !important;
}
.fr-popup .fr-hs.fr-hidden {
  display: none !important;
}
.fr-popup .fr-input-line {
  position: relative;
  padding: 8px 0;
}
.fr-popup .fr-input-line input[type="text"],
.fr-popup .fr-input-line textarea {
  width: 100%;
  margin: 0px 0 1px 0;
  border: none;
  border-bottom: solid 1px #bdbdbd;
  color: #222222;
  font-size: 14px;
  padding: 6px 0 2px;
  background: rgba(0, 0, 0, 0);
  position: relative;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.fr-popup .fr-input-line input[type="text"]:focus,
.fr-popup .fr-input-line textarea:focus {
  border-bottom: solid 2px rgb(66,165,245);
  margin-bottom: 0px;
}
.fr-popup .fr-input-line input + label,
.fr-popup .fr-input-line textarea + label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0);
  -webkit-transition: color 0.2s ease 0s;
  -moz-transition: color 0.2s ease 0s;
  -ms-transition: color 0.2s ease 0s;
  -o-transition: color 0.2s ease 0s;
  z-index: 1;
}
.fr-popup .fr-input-line input.fr-not-empty:focus + label,
.fr-popup .fr-input-line textarea.fr-not-empty:focus + label {
  color: rgb(66,165,245);
}
.fr-popup .fr-input-line input.fr-not-empty + label,
.fr-popup .fr-input-line textarea.fr-not-empty + label {
  color: #808080;
}
.fr-popup input,
.fr-popup textarea {
  user-select: text;
  -o-user-select: text;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  outline: none;
}
.fr-popup textarea {
  resize: none;
}
.fr-popup .fr-buttons {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 0 2px;
  white-space: nowrap;
  line-height: 0;
  border-bottom: 0px;
}
.fr-popup .fr-buttons::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.fr-popup .fr-buttons .fr-btn {
  display: inline-block;
  float: none;
}
.fr-popup .fr-buttons .fr-btn i {
  float: left;
}
.fr-popup .fr-buttons .fr-separator {
  display: inline-block;
  float: none;
}
.fr-popup .fr-layer {
  width: 225px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 10px;
  display: none;
}
@media (min-width: 768px) {
  .fr-popup .fr-layer {
    width: 300px;
  }
}
.fr-popup .fr-layer.fr-active {
  display: inline-block;
}
.fr-popup .fr-action-buttons {
  z-index: 7;
  height: 36px;
  text-align: right;
}
.fr-popup .fr-action-buttons button.fr-command {
  height: 36px;
  line-height: 1;
  color: rgb(66,165,245);
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  background: none;
  font-size: 16px;
  outline: none;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
  font-weight: 700;
  font-size: 13px;
}
.fr-popup .fr-action-buttons button.fr-command + button {
  margin-left: 24px;
}
.fr-popup .fr-action-buttons button.fr-command:hover,
.fr-popup .fr-action-buttons button.fr-command:focus {
  background: #ebebeb;
  color: rgb(66,165,245);
}
.fr-popup .fr-action-buttons button.fr-command:active {
  background: #d6d6d6;
  color: rgb(66,165,245);
}
.fr-popup .fr-action-buttons button::-moz-focus-inner {
  border: 0;
}
.fr-popup .fr-checkbox {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 1;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  vertical-align: middle;
}
.fr-popup .fr-checkbox svg {
  margin-left: 2px;
  margin-top: 2px;
  display: none;
  width: 10px;
  height: 10px;
}
.fr-popup .fr-checkbox span {
  border: solid 1px #666;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
}
.fr-popup .fr-checkbox input {
  position: absolute;
  z-index: 2;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border: 0 none;
  cursor: pointer;
  height: 16px;
  margin: 0;
  padding: 0;
  width: 16px;
  top: 1px;
  left: 1px;
}
.fr-popup .fr-checkbox input:checked + span {
  background: rgb(66,165,245);
  border-color: rgb(66,165,245);
}
.fr-popup .fr-checkbox input:checked + span svg {
  display: block;
}
.fr-popup .fr-checkbox input:focus + span {
  border-color: rgb(66,165,245);
}
.fr-popup .fr-checkbox-line {
  font-size: 14px;
  line-height: 1.4px;
  margin-top: 10px;
}
.fr-popup .fr-checkbox-line label {
  cursor: pointer;
  margin: 0 5px;
  vertical-align: middle;
  color: #777;
  font-size: 13px;
}
.fr-popup.fr-rtl {
  direction: rtl;
  text-align: right;
}
.fr-popup.fr-rtl .fr-action-buttons {
  text-align: left;
}
.fr-popup.fr-rtl .fr-input-line input + label,
.fr-popup.fr-rtl .fr-input-line textarea + label {
  left: auto;
  right: 0;
}
.fr-popup .fr-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #666666;
  position: absolute;
  top: -8px;
  left: 50%;
  margin-left: -5px;
  display: inline-block;
}
.fr-popup.fr-above .fr-arrow {
  top: auto;
  bottom: -8px;
  border-bottom: 0;
  border-top: 5px solid #666666;
}
.fr-text-edit-layer {
  width: 250px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block !important;
}
.fr-visibility-helper {
  display: none;
  margin-left: 0px !important;
}
@media (min-width: 768px) {
  .fr-visibility-helper {
    margin-left: 1px !important;
  }
}
@media (min-width: 992px) {
  .fr-visibility-helper {
    margin-left: 2px !important;
  }
}
@media (min-width: 1200px) {
  .fr-visibility-helper {
    margin-left: 3px !important;
  }
}
.fr-opacity-0 {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.fr-box {
  position: relative;
}
/**
 * Postion sticky hacks.
 */
.fr-sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
}
.fr-sticky-off {
  position: relative;
}
.fr-sticky-on {
  position: fixed;
}
.fr-sticky-on.fr-sticky-ios {
  position: absolute;
  left: 0;
  right: 0;
  width: auto !important;
}
.fr-sticky-dummy {
  display: none;
}
.fr-sticky-on + .fr-sticky-dummy,
.fr-sticky-box > .fr-sticky-dummy {
  display: block;
}

.fr-placeholder {
  font-size: 14px !important;
}

/*
** Overrides
*/

.fr-box {
  // box-shadow: 0 0 5px rgba(97,97,97, 0.25);
  margin-bottom: 4px;
}

.fr-toolbar,
.fr-popup {
  h1, 
  h2,
  h3,
  h4,
  h5, 
  h6 {
    font-weight: normal;
    font-size: inherit;
  }  

  .icon {
    color: #6d6d6d;
  }
  .fr-disabled .icon { color: #b2b2b2; }

  .fr-btn:not(.fr-disabled):hover {
    .icon { color: #000; }
  }
  .fr-btn.fr-active .icon { color: #C39A2A; }


  .fr-dropdown {
    width: auto!important; 
  }
  .fr-dropdown-menu { 
    .icon { 
      display: block; 
      padding: 4px 0;
    }
  }

  .icon-fullscreen {
    font-size: 29px!important;
    line-height: 29px!important;
    height: 29px!important; 
  }
}

.fr-toolbar .fr-command.fr-btn,
.fr-popup .fr-command.fr-btn {
  height: 43px;

  span {
    font-size: 23px;
    line-height: 23px;
    height: 23px;
    font-weight: normal;

    &.icon-paragraph { font-size: 19px; }
  }
}

.fr-toolbar .fr-command.fr-btn.fr-dropdown i, 
.fr-popup .fr-command.fr-btn.fr-dropdown i, 
.fr-toolbar .fr-command.fr-btn.fr-dropdown span, 
.fr-popup .fr-command.fr-btn.fr-dropdown span, 
.fr-toolbar .fr-command.fr-btn.fr-dropdown img, 
.fr-popup .fr-command.fr-btn.fr-dropdown img {
  margin-left: 0;
}


.fr-toolbar .fr-command.fr-btn.fr-dropdown::after, 
.fr-popup .fr-command.fr-btn.fr-dropdown::after {
  top: 19.5px;
}

.text-button {
    text-transform: uppercase;
    color: #FAFAFA !important;
    font-size: 15px;
    letter-spacing: 2px;
    font-weight: 400;
    display: inline-block;

    &:hover,
    &:focus {
        color: #C39A2A;
        transition: .3s;
    }

    &--line {
        padding: 8px 20px;
        position: relative;
        line-height: 1em;
        font-weight: 700 !important;
        border-top: 1px solid rgba(64,61,76,.75);
        border-bottom: 1px solid rgba(64,61,76,.75);

        &::after {
            content: '\e646';
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin-left: 5px;
            position: relative;
            top: 1px;
            color: #C39A2A;
        }
    }
}

.play-video {
    display: inline-block;
    width: 66px;
    height: 66px;
    background: url("https://static.neocoregames.com/frontend/app/assets/images/community/edit-post-video-play-button.png") no-repeat center center;
    background-size: cover;
    opacity: 0.8;
    transition: opacity .3s;
    &:hover {
        opacity: 1;
        transition: opacity .3s;
    }
}

.mt0 { margin-top: 0px !important; }
.mt5 { margin-top: 5px !important; }
.mt10 { margin-top: 10px !important; }
.mt20 { margin-top: 20px !important; }
.mt30 { margin-top: 30px !important; }
.mt40 { margin-top: 40px !important; }
.mb0 { margin-bottom: 0px !important; }
.mb5 { margin-bottom: 5px !important; }
.mb10 { margin-bottom: 10px !important; }
.mb20 { margin-bottom: 20px !important; }
.mb30 { margin-bottom: 30px !important; }
.mb40 { margin-bottom: 40px !important; }

.read-more {
  text-transform: uppercase;
  display: block;
  width: 100%;
  float: none;
  clear: both;
  color: rgba(255,255,255,.6);
  position: relative;
  text-align: center;
  padding: 5px 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    border-top: 2px dashed rgba(255,255,255,.3);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px dashed rgba(255,255,255,.3);
  }
}